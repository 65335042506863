import { Container, Grid, Paper, Text, rem, Title, Box, Flex } from '@mantine/core';
import { IconManFilled, IconWomanFilled, IconQuote } from '@tabler/icons-react';
import classes from '@/style/Testimonials.module.css';

interface Testimonial {
  name: string;
  title: string;
  quote: React.ReactNode; // Changed from string to ReactNode
  icon: React.ReactNode;
}

const testimonials: Testimonial[] = [
  {
    name: 'Katherine',
    title: 'Retro nostalgia podcaster',
    quote: (
      <>
        Love your site! It&apos;s one of the best detailed sites so far on the net &{' '}
        <Text span fw={700}>
          we love what you&rsquo;re doing for indie podcast creators
        </Text>{' '}
        everywhere.
      </>
    ),
    icon: <IconWomanFilled size={40} color="var(--sunray)" />,
  },
  {
    name: 'Kevin',
    title: 'Legions Podcasts admin',
    quote: (
      <>
        DeepCast has a{' '}
        <Text span fw={700}>
          fantastic set of tools for Podcast hosts
        </Text>
        alike. From Transcriptions, to Summaries, and Entities—it&apos;s a handy Subject and Topic breakdown tool!
      </>
    ),
    icon: <IconManFilled size={40} color="var(--magenta)" />,
  },
  {
    name: 'Annemarie',
    title: 'Top recruiter podcaster',
    quote: (
      <>
        Oh amazing! We LOVE what DeepCast offers that is{' '}
        <Text span fw={700}>
          making our advice ever more accessible
        </Text>
        !
      </>
    ),
    icon: <IconWomanFilled size={40} />,
  },
  {
    name: 'Matthew',
    title: 'Kids category podcaster',
    quote: (
      <>
        Blown away with what their AI is doing in terms of{' '}
        <Text span fw={700}>
          accessibility for our shows
        </Text>
        . These tools{' '}
        <Text span fw={700}>
          add so much to the listener experience
        </Text>
        .
      </>
    ),
    icon: <IconManFilled size={40} color="var(--surf)" />,
  },
];

export function Testimonials() {
  return (
    <Container className={classes.container}>
      <Title order={2} ta="center" mb={rem(40)} className={classes.sectionTitle}>
        What podcasters are saying
      </Title>
      <Grid gutter="md" justify="center">
        {testimonials.map((testimonial, index) => (
          <Grid.Col key={index} span={{ base: 12, sm: 10, md: 6, lg: 6 }}>
            <Paper shadow="md" p="xl" className={classes.testimonialCard}>
              <Box style={{ position: 'relative', paddingLeft: rem(30) }}>
                <IconQuote size={24} className={classes.quoteIcon} />
                <Text size="md" fw={500} mb="md" ml="xs" className={classes.quoteText} lh="lg" c="white">
                  {testimonial.quote}
                </Text>
              </Box>
              <Flex align="center" gap="sm" wrap="nowrap" className={classes.authorInfo}>
                <Box className={classes.iconWrapper}>{testimonial.icon}</Box>
                <Box>
                  <Text fw={500} c="white">
                    {testimonial.name}
                  </Text>
                  <Text size="sm" className={classes.title}>
                    {testimonial.title}
                  </Text>
                </Box>
              </Flex>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
}
