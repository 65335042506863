import { Center, Stack, Title, Text, Group, Image, rem, Transition } from '@mantine/core';
import classes from '@/style/LandingPage.module.css';
import { useDisclosure } from '@mantine/hooks';
import SearchInput from '@/components/ClaimPodcastSearch/SearchInput';
import type { PodcastSearchResult } from '@/types';
import ClaimModal from '@/components/ClaimPodcastSearch/ClaimModal';
import { useEffect, useState } from 'react';
import ImportModal from '@/components/ClaimPodcastSearch/ImportModal';
import PodcasterHeroImg from '@/images/hero-podcaster.png';

export default function ClaimPodcastBlock() {
  const [podcast, setPodcast] = useState<PodcastSearchResult | undefined>(undefined);
  const [opened, { open, close }] = useDisclosure(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Center className={classes['hero-block-center']} w="100%" h={rem(570)}>
      <Stack gap={rem(50)} align="center">
        <Group wrap="nowrap" justify="center" className={classes['hero-block-group']}>
          <Transition duration={300} transition="fade-right" mounted={isMounted} timingFunction="ease-out">
            {(styles) => (
              <Stack className={classes['hero-block-stack']} style={{ ...styles }}>
                <Title order={2} c="white" className={classes['hero-headline']}>
                  You make the shows, let us help with what comes after.
                </Title>
                <Text c="white.3" lh={1.4}>
                  Claim your podcast to access free transcripts, insights, and ready-to-share moments crafted for you
                  and your audience.
                </Text>
                <Text c="white.3" lh={1.4}>
                  It&apos;s free to get started!
                </Text>
                <SearchInput open={open} podcast={podcast} setPodcast={setPodcast} />
                {podcast?.slug ? (
                  <ClaimModal opened={opened} close={close} podcast={podcast} />
                ) : (
                  <ImportModal opened={opened} close={close} podcast={podcast} />
                )}
              </Stack>
            )}
          </Transition>
          <Center w={{ base: 0, md: rem(360) }}>
            <Transition duration={300} transition="fade-down" mounted={isMounted} timingFunction="linear">
              {(styles) => (
                <Image
                  src={PodcasterHeroImg}
                  alt="Podcast Host"
                  maw={rem(360)}
                  visibleFrom="md"
                  className={classes['hero-image']}
                  style={{ ...styles }}
                />
              )}
            </Transition>
          </Center>
        </Group>
      </Stack>
    </Center>
  );
}
