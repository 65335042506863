import {
  Group,
  useCombobox,
  Combobox,
  TextInput,
  Text,
  Stack,
  Button,
  Loader,
  rem,
  useMantineTheme,
  Transition,
  Box,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import classes from '@/style/ClaimPodcastSearch.module.css';
import type { PodcastSearchResult } from '@/types';
import { IconSearch } from '@tabler/icons-react';
import { useDebounceFetcher } from 'remix-utils/use-debounce-fetcher';

interface SearchInputProps {
  open: () => void;
  podcast: PodcastSearchResult | undefined;
  setPodcast: (podcast: PodcastSearchResult | undefined) => void;
}

export default function SearchInput({ open, podcast, setPodcast }: SearchInputProps) {
  const theme = useMantineTheme();
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const fetcher = useDebounceFetcher<PodcastSearchResult[] | []>();
  const [loading, setLoading] = useState(false);
  const [ctaClicked, setCtaClicked] = useState(false);

  const results = fetcher.data;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === 'keyboard') {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex('active');
      }
    },
  });

  const options = results?.map((result) => (
    <Combobox.Option
      key={result.rss_url}
      value={result.title}
      className={classes['dropdown-option']}
      active={result.title === value}
    >
      {result.title}
    </Combobox.Option>
  ));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPodcast(undefined);
    setValue(e.currentTarget.value);
    if (e.currentTarget.value) {
      fetcher.submit({ q: e.currentTarget.value }, { method: 'GET', debounceTimeout: 250 });
      combobox.openDropdown();
      combobox.updateSelectedOptionIndex();
    }
  };

  useEffect(() => {
    setLoading(fetcher.state === 'loading');
  }, [fetcher.state]);

  const placeholderText = 'Enter your podcast title, author email, or feed URL';

  return (
    <Box h={rem(36)} w="100%">
      <Transition mounted={!ctaClicked} transition="scale-x" duration={300} timingFunction="ease-out">
        {(styles) => (
          <Button
            w={rem(300)}
            onClick={() => setCtaClicked(true)}
            color={theme.other.brandColors.sunray}
            className={classes['cta-button']}
            style={{
              ...styles,
              overflow: 'hidden',
            }}
          >
            Claim your podcast
          </Button>
        )}
      </Transition>
      <Transition mounted={ctaClicked} transition="scale-x" duration={400} timingFunction="ease-in" enterDelay={200}>
        {(styles) => (
          <Group
            justify="space-between"
            align="flex-start"
            className={classes['claim-podcast-container']}
            style={styles}
          >
            <Combobox
              onOptionSubmit={(optionValue) => {
                setValue(optionValue);
                setPodcast(results?.find((result) => result.title === optionValue));
                combobox.closeDropdown();
              }}
              middlewares={{ flip: false }}
              store={combobox}
            >
              <fetcher.Form className={classes['form-input']}>
                <Combobox.Target>
                  <Stack className={classes['claim-podcast-input-container']}>
                    <TextInput
                      classNames={{
                        wrapper: classes['dropdown-input'],
                        input: classes['dropdown-input-field'],
                      }}
                      leftSection={<IconSearch size={18} color="white" />}
                      placeholder={isFocused ? '' : placeholderText}
                      size="md"
                      value={value}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      onChange={handleOnChange}
                      onClick={() => combobox.openDropdown()}
                      onFocus={() => {
                        setIsFocused(true);
                        combobox.openDropdown();
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) setIsFocused(false);
                        combobox.closeDropdown();
                      }}
                    />
                    <Text
                      size="xs"
                      className={`${classes['claim-podcast-placeholder']} ${
                        isFocused || value ? classes['claim-podcast-placeholder-focused'] : ''
                      }`}
                    >
                      {placeholderText}
                    </Text>
                  </Stack>
                </Combobox.Target>

                <Combobox.Dropdown hidden={value.trim().length === 0} className={classes['dropdown-menu']}>
                  <Combobox.Options>
                    {loading && (
                      <Combobox.Empty>
                        <Loader size="sm" />
                      </Combobox.Empty>
                    )}
                    {results?.length === 0 ? <Combobox.Empty>No results found</Combobox.Empty> : options}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </fetcher.Form>
            </Combobox>

            <Button
              className={`${classes['claim-button']}`}
              size="md"
              variant="filled"
              onClick={open}
              disabled={!podcast}
              fullWidth
            >
              Let&apos;s go!
            </Button>
          </Group>
        )}
      </Transition>
    </Box>
  );
}
