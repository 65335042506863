import type { ActionFunction, LoaderFunction } from '@remix-run/node';
import { useMantineTheme, Stack, Divider, rem, Transition, Box } from '@mantine/core';

import ClaimPodcastBlock from './claim-podcast-block';
import FaqCtaBlock from './faq-cta-block';
import FeaturesBlock from './features-block';
import PricingBlock from './pricing-block';
import SubheadlineBlock from './subheadline-block';
import AffiliateCards from '@/components/AffiliateCards';
import { get, post } from '@/utils/api';
import { Testimonials } from '@/components/Testimonials';
import { Gradient } from '@/components/Gradient';
import { useEffect, useState } from 'react';
import { useScrollIntoView } from '@mantine/hooks';

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const search = new URLSearchParams(url.search);
  const query = search.get('q');
  if (!query) {
    return [];
  }
  return get(`/podcasts/search/claimable?q=${query}`);
};

export default function Index() {
  const theme = useMantineTheme();
  const [isMounted, setIsMounted] = useState(false);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Stack>
      <ClaimPodcastBlock />
      <Gradient
        h={rem(400)}
        w={rem(400)}
        color1="#20555e"
        color2={theme.other.brandColors.sunray}
        color1Percent={74}
        color2Percent={100}
        style={{ right: '30vw', top: '-20px', transform: 'rotate(20deg)' }}
      />
      <Box mih={rem(2000)}>
        <Transition duration={300} timingFunction="ease" transition="fade-down" mounted={isMounted} keepMounted>
          {(styles) => (
            <Stack style={{ ...styles }}>
              <Divider mt={{ base: rem(36), md: 0 }} mx={'-xl'} color={theme.other.colors.white40} />
              <SubheadlineBlock scrollIntoView={scrollIntoView} />
              <Divider mt={{ base: rem(36), md: 0 }} mx={'-xl'} color={theme.other.colors.white40} />
              <FeaturesBlock />
              <Divider my={rem(36)} mx={'-xl'} color={theme.other.colors.white40} />
              <PricingBlock targetRef={targetRef} />
              <Divider mt={rem(36)} color={theme.other.colors.white40} />
              <Testimonials />
              <Divider mt={rem(36)} color={theme.other.colors.white40} />
              <AffiliateCards />
              <Divider mt={rem(36)} color={theme.other.colors.white40} />
              <FaqCtaBlock />
            </Stack>
          )}
        </Transition>
      </Box>
    </Stack>
  );
}

export const action: ActionFunction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const body = {
      podcast_id: formData.get('podcast_id'),
    };
    return await post('/users/claim/podcast', body);
  } catch (error) {
    console.error(error);
    return null;
  }
};
