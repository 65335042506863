import { Stack, Title, Text, rem } from '@mantine/core';

import PricingCards from '@/components/PricingCards';
import PricingComparison from '@/components/PricingComparison';

type PricingBlockProps = {
  targetRef: React.RefObject<HTMLHeadingElement>;
};

export default function PricingBlock({ targetRef }: PricingBlockProps) {
  return (
    <Stack align="center" style={{ zIndex: 2 }}>
      <Title ta="center" order={2} c="white" ref={targetRef}>
        Plans & Pricing
      </Title>
      <Text ta="center">Start free, scale smart. Choose the plan that fits your podcast journey.</Text>
      <PricingCards />
      <Title ta="center" size="h4" order={2} c="white" mt={rem(20)}>
        Process Your Entire Catalog for Less
      </Title>
      <Text ta="center">Total cost to process 200 episodes</Text>
      <PricingComparison />
    </Stack>
  );
}
